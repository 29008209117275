import { Promotion } from 'src/app/core/models/promotion.model';

export class SuppressPromo {
  static readonly type = '[Promo] Display Promo';
  constructor(public promo: Promotion) {}
}

export class DismissPromoModal {
  static readonly type = '[Promo] Dismiss Promo Modal';
  constructor() {}
}
