<div class="container-fluid footer-body">
  <div
    class="container d-flex flex-wrap flex-row justify-content-around align-content-around"
  >
    <div class="flex mx-2 my-4 text-center text-md-left">
      <div>
        <a href="#" class="footer-link" routerLink="/">Home</a>
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/lessons">Lessons</a>
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/registration"
          >Enroll</a
        >
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/camp">Summer Camp</a>
      </div>
    </div>
    <div class="flex mx-2 my-4 text-center text-md-left">
      <div>
        <a href="#" class="footer-link" routerLink="/faq">F.A.Q.</a>
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/policies"
        >Class Policies</a
        >
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/contact">Contact Us</a>
      </div>
      <div>
        <a href="#" class="footer-link" routerLink="/about">About Us</a>
      </div>
    </div>
    <div class="flex mx-2 my-4 text-center text-md-left">
      <div class="d-flex justify-content-center justify-content-md-start">
        <span class="footer-link"
          ><i class="material-icons">phone_iphone</i>972.475.6471</span
        >
      </div>
      <div class="d-flex justify-content-center justify-content-md-start">
        <span class="footer-link"
          ><i class="material-icons">alternate_email</i
          >rowlettpianostudio@hotmail.com</span
        >
      </div>
      <div class="d-flex justify-content-center justify-content-md-start">
        <div class="footer-link flex">
          <i class="material-icons">location_on</i>
          <span class="d-block">3705 Lakeview Pkwy,</span>
          <span class="d-block">Suite 300</span>
          <span class="d-block">Rowlett, TX 75088</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid bottom-accent-bar"></div>
