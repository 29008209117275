import { CommonModule } from '@angular/common';
import {importProvidersFrom, Injectable, NgModule} from '@angular/core';
import {RouterModule, RouterStateSnapshot, Routes, TitleStrategy} from '@angular/router';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import {Title} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@Injectable()
export class CustomTitleStrategy extends TitleStrategy {
  constructor(private readonly title: Title) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot): void {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} - Rowlett Piano Studio`);
    } else {
      this.title.setTitle(`Music Lessons in Rowlett - Rowlett Piano Studio`);
    }
  }
}

const routes: Routes = [
  {
    path: 'lessons',
    loadChildren: () =>
      import('./lessons/lessons.module').then(m => m.LessonsModule)
  },
  {
    path: 'schedule',
    loadChildren: () =>
      import('./schedule/schedule.module').then(m => m.ScheduleModule)
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'policies',
    loadChildren: () =>
      import('./policies/policies.module').then(m => m.PoliciesModule)
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        m => m.RegistrationModule
      )
  },
  {
    path: 'faq',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'camp',
    loadChildren: () => import('./camp/camp.module').then(m => m.CampModule)
  },
  { path: '', component: HomeComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    })
  ],
  providers: [
      importProvidersFrom(RouterModule.forRoot(routes), BrowserAnimationsModule),
      {
        provide: TitleStrategy,
        useClass: CustomTitleStrategy,
      },
  ]
})
export class RoutingModule {}
