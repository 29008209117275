<nav class="navbar navbar-expand-lg navbar-light bg-light main-nav">
  <div class="container">
    <button
      class="navbar-toggler"
      type="button"
      aria-label="Toggle navigation"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
    >
      <i class="material-icons">menu</i>
    </button>
    <a class="navbar-brand" href="#" routerLink="/">
      <img src="assets/images/logo-inline.png" alt="" />
    </a>
    <div
      class="collapse navbar-collapse"
      id="navbarSupportedContent"
      [collapse]="isCollapsed"
    >
      <ul
        class="nav navbar-nav flex-fill w-100 flex-nowrap justify-content-end"
      >
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="/lessons">Lessons</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="/registration">Register</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="/camp">Summer Camp</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="faq">FAQ</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="contact">Contact Us</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" href="#" routerLink="about">About Us</a>
        </li>
      </ul>
    </div>
  </div>
</nav>

<!--<button-->
<!--  type="button"-->
<!--  class="d-inline d-lg-none sidebar-toggler navbar-toggler my-3"-->
<!--  (click)="isCollapsed = !isCollapsed"-->
<!--  [attr.aria-expanded]="!isCollapsed"-->
<!--  aria-controls="primaryNavigation"-->
<!--&gt;-->
<!--  <i class="material-icons">menu</i>-->
<!--</button>-->
<!--<div class="mobile-logo d-inline d-lg-none">-->
<!--  <img src="assets/images/icon.png" alt="" class="img-fluid" />-->
<!--</div>-->
<!--<nav class="collapse navbar-collapse sidebar" [collapse]="isCollapsed">-->
<!--  <div class="drawer">-->
<!--    <ul class="nav flex-column">-->
<!--      <li-->
<!--        class="nav-item"-->
<!--        routerLinkActive="selected"-->
<!--        [routerLinkActiveOptions]="{ exact: true }"-->
<!--      >-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          href="#"-->
<!--          routerLink="/"-->
<!--          routerLinkActive="active"-->
<!--          [routerLinkActiveOptions]="{ exact: true }"-->
<!--          (click)="closeDrawer()"-->
<!--          >Home</a-->
<!--        >-->
<!--      </li>-->
<!--      <li class="nav-item" routerLinkActive="selected">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          href="#"-->
<!--          routerLink="/connect"-->
<!--          routerLinkActive="active"-->
<!--          (click)="closeDrawer()"-->
<!--          >Get Connected</a-->
<!--        >-->
<!--      </li>-->
<!--      <li class="nav-item" routerLinkActive="selected">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          href="#"-->
<!--          routerLink="/about"-->
<!--          routerLinkActive="active"-->
<!--          (click)="closeDrawer()"-->
<!--          >Who We Are</a-->
<!--        >-->
<!--      </li>-->
<!--      <li class="nav-item" routerLinkActive="selected">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          href="#"-->
<!--          routerLink="/blog"-->
<!--          routerLinkActive="active"-->
<!--          (click)="closeDrawer()"-->
<!--          >Over Coffee</a-->
<!--        >-->
<!--      </li>-->
<!--      <li class="nav-item" routerLinkActive="selected">-->
<!--        <a-->
<!--          class="nav-link"-->
<!--          href="#"-->
<!--          routerLink="/help"-->
<!--          routerLinkActive="active"-->
<!--          (click)="closeDrawer()"-->
<!--          >How To Help</a-->
<!--        >-->
<!--      </li>-->
<!--    </ul>-->
<!--  </div>-->
<!--  &lt;!&ndash;  <div class="drawer-backdrop" (click)="closeDrawer()" [hidden]="isCollapsed"></div>&ndash;&gt;-->
<!--</nav>-->
