import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { FeaturedBannerComponent } from './featured-banner/featured-banner.component';
import { HomeComponent } from './home.component';

@NgModule({
  declarations: [FeaturedBannerComponent, HomeComponent],
  imports: [CommonModule, RouterModule, CoreModule],
  exports: [HomeComponent]
})
export class HomeModule {}
