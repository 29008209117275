import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-mobile-navigation-bar',
  templateUrl: './mobile-navigation-bar.component.html',
  styleUrls: ['./mobile-navigation-bar.component.scss']
})
export class MobileNavigationBarComponent implements OnInit {
  isCollapsed: boolean;
  constructor(private router: Router) {}

  ngOnInit() {
    this.isCollapsed = true;
    this.router.events
      .pipe(
        filter(evt => {
          return evt instanceof NavigationEnd;
        })
      )
      .subscribe(navEnd => {
        this.closeDrawer();
      });
  }

  closeDrawer() {
    this.isCollapsed = true;
  }
}
