import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { DesktopNavigationBarComponent } from './desktop-navigation-bar/desktop-navigation-bar.component';
import { MobileNavigationBarComponent } from './mobile-navigation-bar/mobile-navigation-bar.component';
import { NavigationBarComponent } from './navigation-bar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AboutMenuComponent } from './about-menu/about-menu.component';
import { CampMenuComponent } from './camp-menu/camp-menu.component';
import { EnrollMenuComponent } from './enroll-menu/enroll-menu.component';

@NgModule({
  declarations: [
    NavigationBarComponent,
    MobileNavigationBarComponent,
    DesktopNavigationBarComponent,
    AboutMenuComponent,
    CampMenuComponent,
    EnrollMenuComponent
  ],
  imports: [CommonModule, CollapseModule, RouterModule, BsDropdownModule],
  exports: [NavigationBarComponent]
})
export class NavigationBarModule {}
