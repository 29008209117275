<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12 p-0">
      <app-promotion-hero></app-promotion-hero>
    </div>
  </div>
  <div class="row">
    <div class="col-12 section-teach text-right">
      <div class="container">
        <div class="row justify-content-end">
          <div class="col-12 col-lg-6">
            <h2 class="headline">Focus On What Matters.</h2>
            <div class="headline-subtitle">
              We don’t sell or rent instruments. This allows us to specialize on
              one thing - providing the highest quality of music instruction to
              our students.
            </div>
            <div class="call-to-action dark">
              <a href="#" routerLink="/about">Learn Who We Are</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 px-0 section-lessons">
      <div class="container section-inner">
        <div class="row">
          <div class="col-12 col-lg-6">
            <h2 class="headline light">Not Just A Piano Studio.</h2>
            <div class="headline-subtitle light">
              We provide private and group lessons for piano and guitar for
              students of all ages.
            </div>
            <div class="call-to-action light">
              <a href="#" routerLink="/lessons">Learn More</a>
            </div>
          </div>
        </div>
        <img
          src="assets/images/guitar-small.png"
          class="floating circle circle-one"
        />
        <img
          src="assets/images/piano-small.png"
          class="floating circle circle-two"
        />
        <img
          src="assets/images/music.png"
          class="floating circle circle-three"
        />
      </div>
    </div>
    <div
      class="col-12 section-age"
    >
      <div class="container">
        <div class="row justify-content-center age-info">
          <div class="col-12 col-lg-8">
            <h2 class="headline">Whether You’re 5 or 85...We Can Teach You.</h2>
            <div class="headline-subtitle">
              We have taught students of all ages. We tailor our lessons to fit
              students of all ages and experience levels to make learning music
              more rewarding.
            </div>
            <div class="call-to-action dark">
              <a href="#" routerLink="/registration">Get Started</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
