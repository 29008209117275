import {importProvidersFrom, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {RouterModule, TitleStrategy} from '@angular/router';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NavigationBarModule } from './core/navigation-bar/navigation-bar.module';
import { HomeModule } from './home/home.module';
import {CustomTitleStrategy, RoutingModule} from './routing.module';
import { PromoState } from './states/promo/promo.state';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutingModule,
    RouterModule,
    NavigationBarModule,
    HomeModule,
    CoreModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    NgxsModule.forRoot([PromoState], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: ['promos']
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    })
  ],
  providers:[],
  bootstrap: [AppComponent]
})
export class AppModule {}
