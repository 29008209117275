<div
  *ngIf="isModalShown"
  [config]="{ show: true }"
  (onHidden)="onHidden()"
  bsModal
  #promoModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-auto-name"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content rounded-xl">
      <div class="modal-body p-0">
        <button
          type="button"
          class="btn-close close"
          aria-label="Close"
          (click)="hideModal()"
        >
          <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button>
        <carousel
          [(activeSlide)]="activePromotionIndex"
          isAnimated="true"
          interval="0"
          pauseOnFocus="true"
          noWrap="true"
          (activeSlideChange)="slideViewed($event)"
        >
          <slide
            *ngFor="let promo of promotions; let index = index"
            (click)="activatePromo(promo.link)"
          >
            <img
              [src]="promo.promoImageUrl"
              [alt]="promo.title"
              class="d-block img-fluid w-100 rounded-xl"
            />
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</div>
