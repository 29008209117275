import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Promotion } from '../models/promotion.model';
import { PromotionService } from '../services/promotion.service';

@Component({
  selector: 'app-promotion-hero',
  templateUrl: './promotion-hero.component.html',
  styleUrls: ['./promotion-hero.component.scss']
})
export class PromotionHeroComponent implements OnInit {
  promotions: Observable<Promotion[]>;
  activePromotionIndex = 0;
  constructor(
    private readonly promotionService: PromotionService,
    private readonly router: Router
  ) {}

  ngOnInit(): void {
    this.promotions = this.promotionService.getActivePromotions();
  }

  activatePromo(link: string) {
    if (link) {
      this.router.navigate([link]);
    }
  }
}
