import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FooterComponent } from './footer/footer.component';
import { NavigationBarModule } from './navigation-bar/navigation-bar.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { PromotionHeroComponent } from './promotion-hero/promotion-hero.component';
import { PromotionModalComponent } from './promotion-modal/promotion-modal.component';

@NgModule({
  declarations: [
    PricingCardComponent,
    FooterComponent,
    PageNotFoundComponent,
    PromotionHeroComponent,
    PromotionModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationBarModule,
    CarouselModule,
    ModalModule
  ],
  exports: [
    PromotionHeroComponent,
    PromotionModalComponent,
    PricingCardComponent,
    FooterComponent,
    PageNotFoundComponent,
    NavigationBarModule
  ]
})
export class CoreModule {}
