import { Injectable } from '@angular/core';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private assetHost = environment.assetUrl;

  constructor() {}

  getAsset(path: string): string {
    // return this.assetHost + '/' + path;
    return '/assets/images/' + path;
  }
}
