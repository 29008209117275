<li class="nav-item dropdown" dropdown #dropdown="bs-dropdown" (mouseleave)="dropdown.hide()">
  <a href="#" dropdownToggle class="nav-link dropdown-toggle" (mouseenter)="dropdown.show()" routerLink="/camp">Summer Camps</a>
  <ul *dropdownMenu class="dropdown-menu" role="menu">
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="blast-off-piano">Blast Off With Piano</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="blast-off-guitar">Blast Off With Guitar</a></li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp/kidzrock">Kidzrock Band Camp</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="intro-jazz">Intro to Jazz</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="favorite-themes">Favorite Themes</a></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="piano-band">Piano Band Camp</a></li>
    <li class="divider dropdown-divider"></li>
    <li role="menuitem"><a class="dropdown-item" href="#" routerLink="/camp" fragment="private-summer-lessons">Ongoing Private Summer Lessons</a></li>
  </ul>
</li>
