import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable, withLatestFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  DismissPromoModal,
  SuppressPromo
} from '../../states/promo/promo.actions';
import { PromoState } from '../../states/promo/promo.state';
import { Promotion } from '../models/promotion.model';
import { PromotionService } from '../services/promotion.service';

@Component({
  selector: 'app-promotion-modal',
  templateUrl: './promotion-modal.component.html',
  styleUrls: ['./promotion-modal.component.scss']
})
export class PromotionModalComponent implements OnInit {
  @ViewChild('promoModal', { static: false }) promoModal?: ModalDirective;
  promotions: Promotion[];
  isModalShown = false;
  modalConfig = {
    backdrop: false,
    ignoreBackdropClick: true,
    static: false
  };
  activePromotionIndex = 0;

  @Select(PromoState.suppressedPromos) suppressedPromos$: Observable<string[]>;
  @Select(PromoState.allowShowModal) allowShowModal$: Observable<boolean>;

  constructor(
    private readonly promoService: PromotionService,
    private readonly router: Router,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.promoService
      .getActivePromotions()
      .pipe(
        withLatestFrom(this.suppressedPromos$, (promos, suppressedPromos) => ({
          promos,
          suppressedPromos
        })),
        map(({ promos, suppressedPromos }) =>
          promos.filter(
            p =>
              suppressedPromos === undefined ||
              !suppressedPromos.some(sp => sp === p.id)
          )
        ),
        withLatestFrom(this.allowShowModal$, (promos, allowShowModal) => ({
          promos,
          allowShowModal
        }))
      )
      .subscribe(({ promos, allowShowModal }) => {
        this.promotions = promos;
        if (this.promotions.length > 0) this.isModalShown = allowShowModal;
      });
  }

  hideModal() {
    this.promoModal?.hide();
  }

  onHidden() {
    this.store.dispatch(new DismissPromoModal());
  }

  slideViewed(index: number) {
    const viewedPromo = this.promotions[index];
    this.store.dispatch(new SuppressPromo(viewedPromo));
  }

  activatePromo(link: string) {
    if (link) {
      this.router.navigate([link]);
      this.promoModal.hide();
    }
  }
}
