import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title
  ) {}
  ngOnInit() {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter(evt => evt instanceof NavigationEnd),
        map(evt => evt as NavigationEnd)
      )
      .subscribe(evt => {
        const title = this.route.snapshot.data.title ?? appTitle;
        this.titleService.setTitle(title);
        if (environment.analytics) {
          gtag('config', 'UA-164604596-1', {
            page_path: evt.urlAfterRedirects,
            page_title: title
          });
          fbq('track', 'PageView');
        }
      });
  }
}
