import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { iif, insertItem, patch, updateItem } from '@ngxs/store/operators';
import { UserPromotion } from '../../core/models/suppressed-promotion.model';
import { DismissPromoModal, SuppressPromo } from './promo.actions';

export interface PromoStateModel {
  modalSessionPauseUntil?: Date;
  userPromos: UserPromotion[];
}

const maxPromoPresentations = 3; // TODO: change this to 3

@State<PromoStateModel>({
  name: 'promos',
  defaults: {
    modalSessionPauseUntil: null,
    userPromos: []
  }
})
@Injectable()
export class PromoState {
  @Action(SuppressPromo)
  suppressPromo(ctx: StateContext<PromoStateModel>, action: SuppressPromo) {
    const state = ctx.getState();
    const suppressUntil = new Date();
    suppressUntil.setDate(suppressUntil.getDate() + 7);
    const existingPromo = state.userPromos.find(
      up => up.promotionId === action.promo.id
    );
    const shownAttempts =
      existingPromo !== undefined ? existingPromo.shownAttempts + 1 : 1;

    ctx.setState(
      patch<PromoStateModel>({
        userPromos: iif(
          existingPromo !== undefined,
          updateItem(
            up => up.promotionId === action.promo.id,
            patch({
              shownAttempts,
              suppressUntil
            })
          ),
          insertItem({
            promotionId: action.promo.id,
            suppressUntil,
            shownAttempts
          })
        )
      })
    );
  }

  @Action(DismissPromoModal)
  dismissPromoModal(
    ctx: StateContext<PromoStateModel>,
    action: DismissPromoModal
  ) {
    let today = new Date();
    const offset = 1000 * 60 * 60 * 4; // 4 hours
    const suppressUntil = new Date();
    suppressUntil.setUTCMilliseconds(today.getUTCMilliseconds() + offset);
    ctx.setState(
      patch<PromoStateModel>({
        modalSessionPauseUntil: suppressUntil
      })
    );
  }

  @Selector()
  static allowShowModal(state: PromoStateModel) {
    if (!state?.modalSessionPauseUntil) return true;
    return !this.checkTemporarySuppression(state.modalSessionPauseUntil);
  }

  @Selector()
  static suppressedPromos(state: PromoStateModel) {
    return [];

    const filterPromos =
      state?.userPromos?.filter(
        promo =>
          this.checkTemporarySuppression(promo.suppressUntil) ||
          promo.shownAttempts >= maxPromoPresentations
      ) ?? [];
    return filterPromos.map(promo => promo.promotionId);
  }

  static checkTemporarySuppression(suppressUntil: Date) {
    const today = new Date();
    let suppressionDate = new Date(suppressUntil);
    return suppressionDate.getTime() > today.getTime();
  }
}
