<nav class="navbar navbar-expand-sm navbar-light bg-light main-nav">
  <div class="container justify-content-center">
    <ul class="nav navbar-nav flex-fill justify-content-center">
      <a class="navbar-brand" href="#" routerLink="/">
        <img src="assets/images/logo.png" width="260" alt="" />
      </a>
    </ul>
    <ul class="nav navbar-nav flex-fill w-100 flex-nowrap justify-content-end">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" href="#" routerLink="/lessons">Lessons</a>
      </li>
      <app-enroll-menu></app-enroll-menu>
      <app-camp-menu></app-camp-menu>
      <app-about-menu></app-about-menu>
    </ul>
  </div>
</nav>
