import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ImageService } from 'src/app/image.service';
import { Promotion } from '../models/promotion.model';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {
  constructor(private readonly imageService: ImageService) {}

  private readonly promotions: Promotion[] = [
    {
      id: `09c291ab-831e-41a9-b440-0e6fcb361358`,
      title: 'Blast Off Summer Camp Classes Open Now',
      heroImageUrl: '/assets/images/last_off_hero.jpg',
      promoImageUrl: '/assets/images/last_off_promo.jpg',
      active: true,
      link: '/camp',
      startDate: new Date(2022, 1, 1),
      endDate: new Date(2022, 6, 30)
    },
    {
      id: '5242b1b1-b771-4dc2-a98d-84ef52ce1088',
      title: 'Registration 50% Off - Sign Up by May 31st',
      heroImageUrl:  '/assets/images/half_off_registration_hero.png',
      promoImageUrl: '/assets/images/half_off_registration_promo.png',
      active: true,
      link: '/registration',
      startDate: new Date(2022, 1, 1),
      endDate: new Date(2022, 12, 31)
    },
    {
      id: '09c7c464-7c45-491b-9cee-e3cecbd37efd',
      title: 'Fall Registration Now Open!',
      heroImageUrl: '/assets/images/fall_registration_hero.png',
      promoImageUrl: '/assets/images/all_registration_promo.png',
      active: true,
      link: '/registration',
      startDate: new Date(2022, 7, 1),
      endDate: new Date(2022, 10, 30)
    }
  ];

  getActivePromotions(): Observable<Promotion[]> {
    const today = new Date();
    return from([
      this.promotions.filter(
        p =>
          p.active &&
          (p.startDate === undefined || p.startDate < today) &&
          (p.endDate === undefined || p.endDate > today)
      )
    ]);
  }
}
