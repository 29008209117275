<carousel
  [(activeSlide)]="activePromotionIndex"
  isAnimated="true"
  interval="8000"
  pauseOnFocus="false"
>
  <slide
    *ngFor="let promo of promotions | async; let index = index"
    (click)="activatePromo(promo.link)"
  >
    <img
      [src]="promo.heroImageUrl"
      [alt]="promo.title"
      class="d-none d-lg-block img-fluid w-100"
    />
    <img
      [src]="promo.promoImageUrl"
      [alt]="promo.title"
      class="d-block d-lg-none img-fluid w-100"
    />
  </slide>
</carousel>
