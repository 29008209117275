import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  showCampBanner = false;

  constructor() {}

  ngOnInit() {
    this.showCampBanner = this.shouldShowCampBanner();
  }

  shouldShowCampBanner() {
    let campStartDate = new Date();
    campStartDate.setMonth(5); //June
    campStartDate.setDate(21);
    let now = new Date();
    let start = new Date();
    let end = new Date();

    let advertiseStartMonth = campStartDate.getMonth() - 3;
    if (advertiseStartMonth < 0) {
      start.setFullYear(campStartDate.getFullYear() - 1);
      advertiseStartMonth = (advertiseStartMonth + 12) % 12;
    }
    start.setMonth(advertiseStartMonth);
    start.setDate(1);

    end.setMonth(campStartDate.getMonth());
    let advertiseEndDate = campStartDate.getDate() + 3;
    if (advertiseEndDate > 30) {
      end.setMonth(campStartDate.getMonth() + 1);
    }
    end.setDate(advertiseEndDate % 30);

    let startTime = start.getTime();
    let endTime = end.getTime();
    let today = now.getTime();

    return today >= startTime && today <= endTime;
  }
}
